/*
 * Document: Default (Template)
 * 
 * Imports
 *      Twitter Bootstrap
 * Modules
 *      Initialize Modules
 *      Selectize
 *      CK Editor
 * Livewire
 *      Update Component 
 *      Pagination
 * Events
 *      Submit Target
 */

"use strict";

/* ================================ */
/* Imports					
/* ================================ */

/* (Imports) Twitter Bootstrap
   ----------------------------- */
window.bootstrap = require('bootstrap');

/* ================================ */
/* Modules					
/* ================================ */

/* (Modules) Initialize Modules
   ----------------------------- */
jQuery(function ($) {
    initializeModules();
});

function initializeModules() {
    initSelectize();
    initEditor();
    initValidationMonitor();
}

/* (Modules) Selectize
   ----------------------------- */
function initSelectize() {
    if(typeof($.fn.selectize) === 'function') {
        $('[data-selectize]:not(.selectized)').each(function() {

            // Clear
            $(this).selectize()[0].selectize.destroy();

            // Tags
            if($(this).is('input')) {
                $(this).selectize({
                    plugins: ["remove_button"],
                    delimiter: ",",
                    persist: false,
                    create: function (input) {
                        return {
                            value: input,
                            text: input,
                        };
                    },
                    onChange: function() {
                        this.$input[0].dispatchEvent(new Event('input'));
                    }
                });
            }

            // Multi-Select
            if($(this).is('select')) {
                $(this).selectize({
                    plugins: ["remove_button"],
                    onChange: function() {
                        this.$input[0].dispatchEvent(new Event('change'));
                    }
                });
            }
        });
    }
}

/* (Modules) CK Editor
   ----------------------------- */
function initEditor() {
    $('[data-init="ckeditor"]').each(function() {
        ClassicEditor.create($(this)[0], {
            toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo'],
        })
        .then( editor => {
            editor.model.document.on('change:data', () => {
                editor.updateSourceElement();
                document.getElementById($(this).attr('id')).dispatchEvent(new Event('input'));
                $(this).trigger('change');
            } );
        } );
    });
}

/* (Modules) Validation Monitor
   ----------------------------- */
function initValidationMonitor() {
    var validationError = false,
        validationArray = [];

    $('[data-validation-errors]').empty();

    $('[data-validation-teaser]').each(function() {
        if($(this).val().length > 250) {
            validationArray.push('Teaser (' + $(this).attr('data-validation-locale') + ') is too long');
            validationError = true;
        }
    });

    $('[data-validation-content]').each(function() {
        if(! $(this).val().match(/\S+/g)) {
            return true;
        }

        if($(this).val().match(/\S+/g).length < 150) {
            validationArray.push('Content (' + $(this).attr('data-validation-locale') + ') is too short');
            validationError = true;
        }

        if($(this).val().match(/\S+/g).length > 450) {
            validationArray.push('Content (' + $(this).attr('data-validation-locale') + ') is too long');
            validationError = true;
        }
    });

    if(validationArray.length === 0) {
        $('[data-validation-errors]').html('<p class="fs-sm">No issue detected.</p>');
    } else {
        $('[data-validation-errors]').append('<ul class="list-unstyled"></ul>');
        $.each(validationArray, function(index, value) {
            $('[data-validation-errors] ul').append('<li class="d-flex align-items-center fs-sm">' + value + '</li>');
        });
    }
}

$(document).on('keyup change', '[data-validation-monitor]', function() {
    initValidationMonitor();
});

/* ================================ */
/* Livewire					
/* ================================ */

/* (Livewire) Update Component
   ----------------------------- */
window.addEventListener('componentUpdated', event => {
    initializeModules();
});

/* (Livewire) Pagination
   ----------------------------- */
$(document).on('click', '.nav-pagination .page-link', function () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return false;
});

/* (Livewire) Scroll to top
   ----------------------------- */
window.addEventListener('scrollToTop', event => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
});


import passwordVisibilityToggle from './components/password-visibility-toggle';
/*
import inputFormatter from './components/input-formatter';
import formValidation from './components/form-validation';
*/
import stickyNavbar from './components/sticky-navbar';
import smoothScroll from './components/smooth-scroll';
import scrollTopButton from './components/scroll-top-button';
/*
import tooltip from './components/tooltip';
import popover from './components/popover';
import toast from './components/toast';
import rangeSlider from './components/range-slider';
import dropdownSelect from './components/dropdown-select';
*/
import carousel from './components/carousel';
import gallery from './components/gallery';
import lightbox from './components/lightbox';
/*
import datePicker from './components/date-picker';
import subscriptionForm from './components/subscription-form';
import interactiveMap from './components/interactive-map';
import cursorParallax from './components/cursor-parallax';
import elementParallax from './components/element-parallax';
import fileUploader from './components/file-uploader';
import bindedInputValue from './components/binded-input-value';
import masterCheckbox from './components/master-checkbox';
*/
import toggleClass from './components/toggle-class';